import React from "react";
import BaseLayout from "../components/layouts/baseLayout";
import { Container, Col, Row } from "react-bootstrap";
import WorkingHours from "../components/contact/workingHours";
import ContactForm from "../components/contact/contactForm";
import TextJumbotron from "../components/util/textJumbotron";
import PhoneNumber from "../components/social/phoneNumber";
import Address from "../components/social/address";
import Email from "../components/social/email";
export default function ContactPage() {
  return (
    <BaseLayout pageTitle="Contact Great Minds Academy">
      <TextJumbotron heading="Contact Us" />
      <Container>
        <Row className="p-3 mb-3 text-center">
          <Col xs={12} lg={4} className="pb-3">
            <PhoneNumber iconClassName="text-dark" />
          </Col>
          <Col xs={12} lg={4} className="pb-2">
            <Address iconClassName="text-dark" />
          </Col>
          <Col xs={12} lg={4} className="pb-2">
            <Email iconClassName="text-dark" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <ContactForm />
          </Col>
          <Col xs={12} lg={6}>
            <WorkingHours />
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
