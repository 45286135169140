import React from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";

export default function ContactForm() {
  return (
    <Container>
      <Row>
        <Col>
          <Form method="post" action="https://formspree.io/f/mnqowgoo">
            {/* Name */}
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                className="text-center"
                type="text"
                placeholder="Name*"
                required
                name="name"
              />
            </Form.Group>

            {/* Email */}
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                className="text-center"
                type="email"
                placeholder="Email*"
                required
                name="email"
              />
              <Form.Text className="text-center text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
               <Form.Group controlId="formBasicEmail">
              <Form.Control
                className="text-center"
                type="tel"
                placeholder="Phone*"
                name="phone"
                required
              />               
              </Form.Group>               
              <Form.Group controlId="formBasicEmail">
              <Form.Control
                className="text-center"
                type="text"
                placeholder="Grade*"
                name="grade"
                required
              />
              </Form.Group>               
              

            {/* Subject */}
            <Form.Group controlId="formBasicSubject" >
               <select name="subject" class="form-control" id="sel1" required>
               <option value="">Program of choice*</option>  
    <option value="Preschool">Preschool</option>
    <option value="Elementary Tutoring">Elementary Tutoring</option>
    <option value="Junior High Tutoring">Junior High Tutoring</option>
    <option value="Senior High Tutoring">Senior High Tutoring</option>
    <option value="Other">Other</option>
  </select>
            </Form.Group>

            {/* Message */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control
                className="text-center"
                as="textarea"
                rows={6}
                placeholder="Message*"
                required
                name="message"
              />
            </Form.Group>
            <Container>
              <Row>
                <Col className="text-center">
                  <Button className="" name="submit" variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
