import React from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import style from "styled-components";

const CenteredRow = style.td`
    text-align:center;
`;

export default function WorkingHours() {
  return (
    <Container>
      <Row>
        <Col>
          <Table bordered hover>
            <thead className="text-center">
              <tr>
                <th colSpan="2">Working Hours</th>
              </tr>
              <tr>
                <th>Day</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <CenteredRow>Monday</CenteredRow>
                <CenteredRow>8:00 AM - 5:00 PM</CenteredRow>
              </tr>
              <tr>
                <CenteredRow>Tuesday</CenteredRow>
                <CenteredRow>8:00 AM - 5:00 PM</CenteredRow>
              </tr>
              <tr>
                <CenteredRow>Wednesday</CenteredRow>
                <CenteredRow>8:00 AM - 5:00 PM</CenteredRow>
              </tr>
              <tr>
                <CenteredRow>Thursday</CenteredRow>
                <CenteredRow>8:00 AM - 5:00 PM</CenteredRow>
              </tr>
              <tr>
                <CenteredRow>Friday</CenteredRow>
                <CenteredRow>8:00 AM - 5:00 PM</CenteredRow>
              </tr>
              <tr>
                <CenteredRow>Saturday</CenteredRow>
                <CenteredRow>8:00 AM - 5:00 PM</CenteredRow>
              </tr>
              <tr>
                <CenteredRow>Sunday</CenteredRow>
                <CenteredRow>
                  <strong>Closed</strong>
                </CenteredRow>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
