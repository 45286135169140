import React from "react";
import { MAIL_TO_ADDRESS } from "../../constants/constants";
import { Container, Row, Col } from "react-bootstrap";
import EmailIcon from "../../images/svg/email.inline.svg";

export default function Email({ className, iconClassName, textClassName }) {
  return (
    <>
      <Container className={className}>
        <Row>
          <Col xs={1} className="my-auto mx-auto pt-1">
            <a
              className={iconClassName}
              href={"mailto:" + MAIL_TO_ADDRESS}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Company Email Address."
            >
              <span className="pt-5">
                <EmailIcon />
              </span>
            </a>
          </Col>
          <Col xs={10}>
            <a
              className={textClassName}
              href={"mailto:" + MAIL_TO_ADDRESS}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Company Email Address."
            >
              {MAIL_TO_ADDRESS}
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}
