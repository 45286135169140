import React from "react";
import { PHONE_NUMBER } from "../../constants/constants";
import { Container, Row, Col } from "react-bootstrap";
import PhoneIcon from "../../images/svg/phone.inline.svg";
export default function PhoneNumber({
  className,
  iconClassName,
  textClassName,
}) {
  return (
    <Container className={className}>
      <Row>
        <Col xs={1} className="my-auto mx-auto pt-1">
          <a
            className={iconClassName}
            href={"tel:" + PHONE_NUMBER}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Phone icon."
          >
            <PhoneIcon />
          </a>
        </Col>
        <Col xs={10}>
          <a
            className={textClassName}
            href={"tel:" + PHONE_NUMBER}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Phone Number Telephone Link."
          >
            {PHONE_NUMBER}
          </a>
        </Col>
      </Row>
    </Container>
  );
}
